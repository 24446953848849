<template>
  <b-menu-item
    :icon="node.children && node.children.length ? 'format-list-bulleted-square' : 'code-not-equal-variant'"
    expanded
    class="menu-item"
    :title="node.label"
    @click="notifyItemSelect()"
  >
    <template #label="slotProps">
      {{ concatenatedValue(node.label, 30) }}
      <b-icon
        v-if="node.children && node.children.length"
        class="is-pulled-right"
        :icon="slotProps.expanded ? 'menu-up' : 'menu-down'"
      />
      <component
        :is="itemDropdown"
        v-if="node.hasOptions"
        :node="computedNode"
      />
    </template>
    <TreeItem
      v-for="(childNode, cIndex) in node.children"
      :key="cIndex"
      :node="childNode"
      :item-dropdown="itemDropdown"
      :show-leaf-node-options="showLeafNodeOptions"
      :show-parent-node-options="showParentNodeOptions"
    />
  </b-menu-item>
</template>

<script >
import { computed, defineAsyncComponent } from '@vue/composition-api';
import { menuTreeEventBus, ON_ITEM_SELECT } from './variableTreeEventBus';
const TreeItem = defineAsyncComponent(() => import('./TreeItem.vue'));
const __sfc_main = {};
__sfc_main.props = {
  node: {
    type: Object,
    default: () => {}
  },
  itemDropdown: {
    type: Object,
    default: null,
    description: 'Component for rendering dropdown options of a variable item in tree'
  },
  showParentNodeOptions: {
    type: Boolean,
    default: false,
    description: 'If set to true, options dropdown will be rendered in parent nodes (collapsable headers)'
  },
  showLeafNodeOptions: {
    type: Boolean,
    default: true,
    description: 'If set to true, options dropdown will be rendered in leaf nodes'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const computedNode = computed(() => {
    const {
      label,
      connectionPath,
      children,
      hasOptions,
      ...restNode
    } = props.node;

    if (children) {
      // add relevant info for parent node as parent nodes don't maintain any other variable info
      restNode.name = connectionPath;
    }

    return restNode;
  });

  const concatenatedValue = (value, length) => {
    if (value.length > length) {
      return value.substr(0, length) + '...';
    }

    return value;
  }; //-- select item logic --//


  const notifyItemSelect = () => {
    if (!props.node.children) {
      menuTreeEventBus.publish(ON_ITEM_SELECT, computedNode.value);
    }
  };

  return {
    computedNode,
    concatenatedValue,
    notifyItemSelect
  };
};

__sfc_main.components = Object.assign({
  TreeItem
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.menu-item {
  a {
    &:hover {
      .options-icon {
        visibility: visible;
      }
    }
    .options-icon {
      visibility: hidden;
      &.active {
        visibility: visible;
      }
    }
    >.dropdown {
      float: right;
      height: 1.5rem;
    }
  }
}
</style>
